// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---changelog-mdx": () => import("./../CHANGELOG.mdx" /* webpackChunkName: "component---changelog-mdx" */),
  "component---src-pages-buttons-and-links-mdx": () => import("./../src/pages/Buttons-and-links.mdx" /* webpackChunkName: "component---src-pages-buttons-and-links-mdx" */),
  "component---src-pages-card-loading-mdx": () => import("./../src/pages/CardLoading.mdx" /* webpackChunkName: "component---src-pages-card-loading-mdx" */),
  "component---src-pages-card-mdx": () => import("./../src/pages/Card.mdx" /* webpackChunkName: "component---src-pages-card-mdx" */),
  "component---src-pages-checkbox-option-mdx": () => import("./../src/pages/CheckboxOption.mdx" /* webpackChunkName: "component---src-pages-checkbox-option-mdx" */),
  "component---src-pages-collapsible-mdx": () => import("./../src/pages/Collapsible.mdx" /* webpackChunkName: "component---src-pages-collapsible-mdx" */),
  "component---src-pages-forms-mdx": () => import("./../src/pages/Forms.mdx" /* webpackChunkName: "component---src-pages-forms-mdx" */),
  "component---src-pages-icon-mdx": () => import("./../src/pages/Icon.mdx" /* webpackChunkName: "component---src-pages-icon-mdx" */),
  "component---src-pages-input-mdx": () => import("./../src/pages/Input.mdx" /* webpackChunkName: "component---src-pages-input-mdx" */),
  "component---src-pages-input-error-mdx": () => import("./../src/pages/InputError.mdx" /* webpackChunkName: "component---src-pages-input-error-mdx" */),
  "component---src-pages-modal-mdx": () => import("./../src/pages/Modal.mdx" /* webpackChunkName: "component---src-pages-modal-mdx" */),
  "component---src-pages-nav-bar-mdx": () => import("./../src/pages/NavBar.mdx" /* webpackChunkName: "component---src-pages-nav-bar-mdx" */),
  "component---src-pages-nav-footer-mdx": () => import("./../src/pages/NavFooter.mdx" /* webpackChunkName: "component---src-pages-nav-footer-mdx" */),
  "component---src-pages-pagination-mdx": () => import("./../src/pages/Pagination.mdx" /* webpackChunkName: "component---src-pages-pagination-mdx" */),
  "component---src-pages-select-mdx": () => import("./../src/pages/Select.mdx" /* webpackChunkName: "component---src-pages-select-mdx" */),
  "component---src-pages-radio-option-mdx": () => import("./../src/pages/RadioOption.mdx" /* webpackChunkName: "component---src-pages-radio-option-mdx" */),
  "component---src-pages-spinner-mdx": () => import("./../src/pages/Spinner.mdx" /* webpackChunkName: "component---src-pages-spinner-mdx" */),
  "component---src-pages-toggle-mdx": () => import("./../src/pages/Toggle.mdx" /* webpackChunkName: "component---src-pages-toggle-mdx" */),
  "component---src-pages-textarea-mdx": () => import("./../src/pages/Textarea.mdx" /* webpackChunkName: "component---src-pages-textarea-mdx" */),
  "component---src-pages-typography-mdx": () => import("./../src/pages/Typography.mdx" /* webpackChunkName: "component---src-pages-typography-mdx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-mdx": () => import("./../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */)
}

